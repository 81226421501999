import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./pages";
import SigninPage from "./pages/signin";
import Projects from "./pages/projects";
import ResumePage from "./pages/resume";
import ChukksPage from "./pages/chukks";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/" component={Home} exact />
        <Route path="/signin" component={SigninPage} exact />
        {/* <Route path="/projects" component={Projects} /> */}
        <Route path="/profile" component={ResumePage} />
        <Route path="/chukks" component={ChukksPage} />
      </Switch>
    </Router>
  );
}

export default App;
