import React, {useState} from 'react'
import {
    Container,
    Form,
    FormButton,
    FormH1,
    FormInput,
    FormLabel,
    FormWrap,
    Icon,
    Text,
    FormContent,
    ErrorBox
} from './SigninElement'

const SignIn = () => {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errorState, setErrorState] = useState(false);
    const [data, setData] = useState(false);
    const [useval, setUseval] = useState('');
    const [passval, setPassval] = useState('');


    const changer = (event) => {
        if (event.target.type === 'email') {
            console.log('its email')
            setUseval(event.target.value)
        } else {
            setPassval(event.target.value)
            console.log('its password')
        }
        // console.log(event.target.type)
    }

    const makesignin = () => {

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(
                {"email": useval, "password": passval}
            )
        };

        fetch('https://661e4d9e5c06.ngrok.io/api/user/login', requestOptions).then(response => {
            console.log(response.ok)
            if (!response.ok) {
                setErrorState(true)
                setUseval('')
                setPassval('')
            }
            setErrorState(false)
            return response.json()
        }).then(data => setData(data)).catch(function () {
            setErrorState(true)
            console.log("error");
        });
        console.log(useval);
        console.log(passval);
    }

    return (
        <Container>
            <FormWrap>
                <Icon to="/">Sheri.in</Icon>
                <FormContent>
                    <Form action="#">
                        <FormH1>Sign In To your Account</FormH1>
                        <FormLabel htmlFor="for">Email</FormLabel>
                        <FormInput type="email"
                            value={useval}
                            onChange={changer}
                            required/>
                        <FormLabel htmlFor="for">Password</FormLabel>
                        <FormInput type="password"
                            onChange={changer}
                            value={passval}
                            required/>
                        <FormButton type='submit'
                            onClick={makesignin}>Continue</FormButton>
                        <Text>Forgot password !</Text>
                        
                            {
                                errorState ? <ErrorBox><Text>{data.error}</Text></ErrorBox> : ' '
                            }
                        
                    </Form>
                </FormContent>
            </FormWrap>
        </Container>
    )
}


export default SignIn
