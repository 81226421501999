import React, { useState } from 'react'
import Chukks from '../components/Chukks'
import Navbar from '../components/Navbar'
import Sidebar from '../components/Sidebar'
const ChukksPage = () => {
    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    }
    return (
        <div>
            <Sidebar isOpen={isOpen} toggle={toggle} />
            {/* <Navbar toggle={toggle} /> */}
            <Chukks />
        </div>
    )
}

export default ChukksPage;