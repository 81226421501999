import React, { useState } from "react";
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter } from 'react-icons/fa'
import { useHistory } from "react-router-dom";
import {
  HeroContainer,
  HeroBg,
  VideoBg,
  HeroH1,
  HeroP,
  HeroBtnWrapper,
  ArrowForward,
  ArrowRight,
  HeroContent,
  IconManager,
  SocialIcons,
  SocialIconLink
} from "./HeroElements";

// import {
//   FooterContainer,
//   FooterWrap,
//   FooterLinksContainer,
//   FooterLinkItems,
//   FooterLinksWrapper,
//   FooterLinkTitle,
//   FooterLink,
//   SocialMedia,
//   SocialMediaWrap,
//   SocialLogo,
//   WebsiteRights,

// } from './FooterElements'

import './style.css'

const HeroSection = () => {
  const history = useHistory();
  const [hover, setHover] = useState(false);
  const onHover = () => {
    setHover(!hover);
  };

  const submit = () => {
    history.push("profile");
  };

  return (
    <HeroContainer>
      {/* <HeroBg>
        <VideoBg autoPlay loop muted src={Video} type="video/mp4" />
      </HeroBg> */}
      <HeroContent>
        <div class="container">
          <div className="intro-text">
            <HeroH1>I am Sherin Stephen</HeroH1>
            <HeroP>Aiming for stars with cloud platforms and services.</HeroP>
            <IconManager>
              <SocialIcons>
                <SocialIconLink href="https://www.facebook.com/sherin.stephen.735/" target="_blank" aria-label="Facebook">
                  <FaFacebook />
                </SocialIconLink>
                <SocialIconLink href="https://www.instagram.com/sherinstephn/" target="_blank" aria-label="Instagram">
                  <FaInstagram />
                </SocialIconLink>
                <SocialIconLink href="https://twitter.com/SherinStephen6/" target="_blank" aria-label="Twitter">
                  <FaTwitter />
                </SocialIconLink>
                <SocialIconLink href="https://www.linkedin.com/in/sherin-stephen/" target="_blank" aria-label="Linkedin">
                  <FaLinkedin />
                </SocialIconLink>
              </SocialIcons>
            </IconManager>
          </div>
          <div class="moon">
            <div class="hole_1"></div>
            <div class="hole_2"></div>
          </div>
          <div class="meteor meteor-1"></div>
          <div class="meteor meteor-2"></div>
          <div class="meteor meteor-3"></div>
          <div class="meteor meteor-4"></div>
          <div class="meteor meteor-5"></div>

          <div class="starts">
            <div class="star"></div>
            <div class="star"></div>
            <div class="star"></div>
            <div class="star"></div>
            <div class="star"></div>
            <div class="star"></div>
            <div class="star"></div>
            <div class="star"></div>
            <div class="star"></div>
            <div class="star"></div>
            <div class="star"></div>
            <div class="star"></div>
          </div>


        </div>




      </HeroContent>
    </HeroContainer>
  );
};

export default HeroSection;
