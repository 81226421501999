import React, { useState } from 'react'
import Resume from '../components/Resume'
import Navbar from '../components/Navbar'
import Sidebar from '../components/Sidebar'
const ResumePage = () => {
    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    }
    return (
        <div>
            <Sidebar isOpen={isOpen} toggle={toggle} />
            <Navbar toggle={toggle} />
            <Resume />
        </div>
    )
}

export default ResumePage;