import React, { useEffect, useRef } from "react";
import styled from "styled-components";

import {
  ServicesWrapper,
  ServicesContainer,
  ServicesH1,
  ServicesCard,
  ServicesIcon,
  ServicesH2,
  ServicesP,
  SkillBox,
} from "./ServicesElements";

import "./stars.css";
const Skills = () => {
  const starsContainer = useRef(null);

  const generateParticles = (number) => {
    for (let i = 0; i < number; i += 1) {
      const particleElement = document.createElement("div");
      particleElement.setAttribute("class", "particle");
      particleElement.style.animationDuration = `${Math.random() * 3}s`;
      starsContainer.current.appendChild(particleElement);
      particleElement.style.top =
        Math.random() * starsContainer.current.offsetHeight -
        particleElement.offsetHeight +
        "px";
      particleElement.style.left =
        Math.random() * starsContainer.current.offsetWidth -
        particleElement.offsetWidth +
        "px";
      const randomSize = Math.random() * 3;
      particleElement.style.width = randomSize + "px";
      particleElement.style.height = randomSize + "px";
    }
  };
  useEffect(() => {
    generateParticles(250);
  }, []);
  return (
    <ServicesContainer id="skils">
      <div id="stars-container" ref={starsContainer}></div>
      <ServicesH1>My Cloud Expertise</ServicesH1>
      <ServicesWrapper>
        {/* <ServicesCard>
          <ServicesIcon
            style={{ filter: "invert(50%)" }}
            src={
              "https://img.icons8.com/color/480/null/amazon-web-services.png"
            }
          />
          <ServicesH2>AWS</ServicesH2>

          <ServicesP></ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon
            src={"https://img.icons8.com/color/480/null/google-cloud.png"}
          />
          <ServicesH2>GCP</ServicesH2>

          <ServicesP></ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon
            src={"https://img.icons8.com/fluency/480/null/azure-1.png"}
          />
          <ServicesH2>Azure</ServicesH2>

          <ServicesP></ServicesP>
        </ServicesCard> */}
        <Card>
          <Tools>
            <Circle>
              <Red className="box" />
            </Circle>
            <Circle>
              <Yellow className="box" />
            </Circle>
            <Circle>
              <Green className="box" />
            </Circle>
            </Tools>
            <Items>
            <ServicesIcon
            src={"https://img.icons8.com/color/480/null/amazon-web-services.png"}
          />
          <ServicesH2>AWS</ServicesH2>
          </Items>
         
          <div className="card__content"></div>
        </Card>

        <Card>
          <Tools>
            <Circle>
              <Red className="box" />
            </Circle>
            <Circle>
              <Yellow className="box" />
            </Circle>
            <Circle>
              <Green className="box" />
            </Circle>
          </Tools>
          <Items>
            <ServicesIcon
            src={"https://img.icons8.com/color/480/null/google-cloud.png"}
          />
          <ServicesH2>GCP</ServicesH2>
          </Items>
          <div className="card__content"></div>
        </Card>

        <Card>
          <Tools>
            <Circle>
              <Red className="box" />
            </Circle>
            <Circle>
              <Yellow className="box" />
            </Circle>
            <Circle>
              <Green className="box" />
            </Circle>
          </Tools>
          {/* https://img.icons8.com/fluency/480/null/azure-1.png */}
          <Items>
            <ServicesIcon
            src={"https://img.icons8.com/fluency/480/null/azure-1.png"}
          />
          <ServicesH2>Azure</ServicesH2>
          </Items>
          <div className="card__content"></div>
        </Card>
      </ServicesWrapper>
    </ServicesContainer>
  );
};

export default Skills;

const Card = styled.div`
  width: 190px;
  height: 254px;
  margin: 0 auto;
  background-color: #f8fbfe;
  border-radius: 8px;
  z-index: 1;
`;

const Tools = styled.div`
  display: flex;
  align-items: center;
  padding: 9px;
`;

const Circle = styled.div`
  padding: 0 4px;
`;

const Box = styled.div`
  display: inline-block;
  align-items: center;
  width: 10px;
  height: 10px;
  padding: 1px;
  border-radius: 50%;
`;

const Red = styled(Box)`
  background-color: #ff605c;
`;
const Yellow = styled(Box)`
  background-color: #ffbd44;
`;

const Green = styled(Box)`
  background-color: #00ca4e;
`;

const Items = styled.div`
justify-content: center;
    display: flex;
    align-items: center;
    flex-direction: column;
`