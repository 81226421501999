import image1 from '../../images/svg-1.svg';
import image2 from '../../images/svg-2.svg';
import image3 from '../../images/svg-3.svg';
import moments from '../../images/moments.svg';
export const homeObjOne={
    id:'about',
    lightBg:false,
    lightText:true,
    topline:'My Profession',
    headline:'Working as a Cloud Software Engineer at INE 😄',
    description:'As a cloud software engineer at INE, I design, develop, and maintain cloud-based applications and services. My tasks include designing scalable architectures, writing efficient code that are both reliable and cost-effective.',
    buttonLabel:'Know More',
    imgStart:false,
    img:image1,
    alt:'Car',
    dark:true,
    primary:true,
    darkText:false
}
export const homeObjTwo={
    id:'discover',
    lightBg:true,
    lightText:false,
    topline:'Description',
    headline:'Inquisitive',
    description:'I like to study new things. Being knowledgeable about programming or any subject is an ongoing process, and I’m always proactive about seeking new opportunities to develop and grow in my role. Those opportunities could be in the form of training, a conference, listening to a speaker, or taking on a new project, but the motivation is to increase my knowledge of the field.',
    buttonLabel:'Get started',
    imgStart:true,
    img:image2,
    alt:'Car',
    dark:true,
    primary:false,
    darkText:true
}
export const homeObjThree={
    id:'about',
    lightBg:true,
    lightText:false,
    topline:'My interests',
    headline:'Wanderlust',
    description:'I like to Travel because I love to learn about new places, culture, languages and people. I think by travelling a person open’s their eyes to new possibilities. As far as i know we have short period of time to explore the beautiful world so i like to utilize it. I love trekking and hiking in forests. Moreover I also realised just how much I love nature and the quiet it offers',
    buttonLabel:'Get started',
    imgStart:false,
    img:image3,
    alt:'Car',
    dark:true,
    primary:false,
    darkText:true,
}
export const photoBanner={
    id:'about',
    lightBg:false,
    lightText:true,
    topline:'',
    headline:'My Photo Gallery',
    description:'',
    buttonLabel:'Get started',
    imgStart:false,
    img:moments,
    alt:'Car',
    dark:true,
    primary:false,
    darkText:true,
}