import styled from "styled-components";

export const ServicesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #16161a;
  padding-bottom: 200px;
  @media screen and (max-width: 768px) {
    height: 1100px;
  }

  @media screen and (max-width: 480px) {
    height: 1300px;
  }
`;
export const ServicesWrapper = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  grid-gap: 16px;
  padding: 0 50px;

  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 0 20px;
  }
`;
export const SkillCard= styled.div`
padding-top: 100px;
transform-style: preserve-3d;
background-image: url("https://images.pexels.com/photos/1629236/pexels-photo-1629236.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1");
background-position: center center;
width: 100%;
transition: all 0.5s ease-in-out;
border-radius: 10px;
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: center;
box-shadow: rgba(0, 115, 255, 0.925) 0px 20px 50px -25px,;
&:hover {
    background-position: 80% 20%;
    transform: rotate3d(0.5, 1, 0, 30deg);
}
`;

export const  Cardbk = styled.div`
  min-width: 300px;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  padding: 30px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.02);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }
`;
export const  ServicesCard= styled.div`
min-width: 300px;
 min-height: 250px;
 display: flex;
 flex-direction: column;
justify-content: flex-start;
align-items: center;
 backdrop-filter: blur(16px) saturate(180%);
 -webkit-backdrop-filter: blur(16px) saturate(180%);
 background-color: rgba(17, 25, 40, 0.75);
 border-radius: 12px;
 border: 1px solid rgba(255, 255, 255, 0.125);
 padding: 30px;
 &:hover {
  transform: scale(1.02);
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
`;


export const ServicesIcon = styled.img`
  height: 90px;
  width: 90px;
  margin-bottom: 10px;
  transform: translate(0%, 0%);
  animation-name: moveUpDown;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  @keyframes moveUpDown {
    from {
      transform: translate(0%, 0%);
    }
    to {
      transform: translate(0%, -10%); /* set the desired top position */
    }
  }
`;
export const ServicesH1 = styled.h1`
  font-size: 2.5rem;
  color: #fff;
  margin-bottom: 78px !important;
  @media screen and (max-width: 480px) {
    font-size: 2rem;
  }
`;
export const ServicesH2 = styled.h3`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
`;
export const ServicesP = styled.p`
  font-size: 1rem;
  text-align: center;
  margin: 5px;
`;

export const SkillBox = styled.div`
  min-width: 280px;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  max-height: 380px;
  padding: 20px;
  border: 2px dashed;
  border-image-slice: 1;
  border-width: 2px;
  border-image-source: linear-gradient(90deg, rgba(33,36,31,0.9710477941176471) 0%, rgba(46,52,42,1) 45%, rgba(57,72,75,1) 90%);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transform: translate3d(0px, 0px, 50px);
  margin-top: 8px;
  &:hover {
    transform: scale(1.02);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }
`;
export const SkillBox1 = styled.div`
min-width: 280px;
position: absolute;
background-color: rgb(0, 0, 0);
border-radius: 10px;
padding: 10px;
box-shadow: rgb(0, 102, 255) 0px 20px 60px 0px, rgb(0, 200, 255) 0px 18px 36px -18px;
transform: translate3d(0px, 0px, 50px);
`;


