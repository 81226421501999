import React from 'react';
import { ServicesWrapper, ServicesContainer, ServicesH1, ServicesCard, ServicesIcon, ServicesH2, ServicesP } from './ServicesElements';
import Icon1 from '../../images/svg-1.svg';
import Icon2 from '../../images/music.svg';
import Icon3 from '../../images/photography.svg';

const Services = () => {
    return (
        <ServicesContainer id="services">
            <ServicesH1>My Hobbies</ServicesH1>
            <ServicesWrapper>
                <ServicesCard>
                    <ServicesIcon src={Icon1} />
                    <ServicesH2>Learn and code</ServicesH2>
                    <ServicesP>I simply like to learn new things to do. Exploring the way with code and creating new things that make my soul happy.</ServicesP>
                </ServicesCard>
                <ServicesCard>
                    <ServicesIcon src={Icon2} />
                    <ServicesH2>Listening to Music</ServicesH2>
                    <ServicesP>For me it actually stimulates brainwaves and heartbeats into a happy state.It can represent different feelings and barge into the soul with no boundaries or limitations</ServicesP>
                </ServicesCard>
                <ServicesCard>
                    <ServicesIcon src={Icon3} />
                    <ServicesH2>Photography</ServicesH2>
                    <ServicesP>For me it is like freezing the time.I love the fact that pictures capture the sweetest moments and sometimes bitter ones too. Pictures can manifest emotions that words cannot</ServicesP>
                </ServicesCard>
            </ServicesWrapper>
        </ServicesContainer>

    )
}

export default Services
