import styled, { keyframes } from 'styled-components';

export const InfoContainer = styled.div`
color:#fff;
background:${({ lightBg }) => (lightBg ? '#f9f9f9': '#16161a')};

@media screen and (max-width:768px){
    padding:100px 0;
}
`
export const InfoWrapper = styled.div`
display: grid;
z-index:1;
height: 600px;
width:100%;
max-width:1100px;
margin-right: auto;
margin-left:auto;
padding: 0 24px;
justify-content:center;
`
export const InfoRow = styled.div`
display:grid;
grid-auto-columns:minmax(auto,1fr);
align-items: center;
grid-template-areas:${({ imgStart }) => (imgStart ? `'col2 col1'`: `'col1 col2'`)};

@media screen and (max-width:768px){
    grid-template-areas:${({ imgStart }) => (imgStart ? `'col1' 'col2'`: `'col1 col1' 'col2 col2'`)};
}
`
export const Column1 = styled.div`
margin-bottom:15px;
padding:0 15px;
grid-area: col1;
`
export const Column2 = styled.div`
margin-bottom:15px;
padding:0 15px;
grid-area: col2;
`
export const TextWrapper = styled.div`
max-width:540px;
padding-top:0;
padding-bottom:60px;
`
export const TopLine = styled.p`
color:#01bf71;
font-size: 16px;
line-height:16px;
font-weight:700;
letter-spacing:1.5px;
text-transform:uppercase;
margin-bottom:16px;
`
export const Heading = styled.h1`
margin-bottom:24px;
font-size: 48px;
line-height:1.1;
font-weight:600;
color:${({ lightText }) => (lightText ? '#f7f8fa': '#010606')};

@media screen and (max-width:480px){
    font-size:32px;
}
`
export const Subtitle = styled.p`
max-width:440px;
margin-bottom:35px;
font-size:18px;
line-height:24px;
color:${({ darkTest }) => (darkTest ? '#010606': '#fff')};
`
export const BtnWrap = styled.div`
display:flex;
justify-content:flex-start;
`
export const ImgWrap = styled.div`
max-width:555px;
height:100%;
@media screen and (max-width:480px){
    margin-left: -90px;
}
`
export const Img = styled.img`
width:100%;
margin: 0 0 10px 0;
padding-right:0;
`


const glitchAnim = keyframes`
  0% {
    clip-path: inset(50% 50% 50% 50%);
    transform: translate(0px,-10px);
  }

  10% {
    clip-path: inset(31% 0 40% 0);
    transform: translate(-10px,10px);
  }

  20% {
    clip-path: inset(39% 0 15% 0);
    transform: translate(10px,0px);
  }

  30% {
    clip-path: inset(45% 0 40% 0);
    transform: translate(-10px,10px);
  }

  40% {
    clip-path: inset(45% 0 6% 0);
    transform: translate(10px,-10px);
  }

  50% {
    clip-path: inset(14% 0 61% 0);
    transform: translate(-10px,10px);
  }

  60% {
    clip-path: inset(50% 50% 50% 50%);
    transform: translate(10px,-10px);
  }

  70% {
    clip-path: inset(39% 0 15% 0);
    transform: translate(-10px,10px);
  }

  80% {
    clip-path: inset(31% 0 40% 0);
    transform: translate(10px,-10px);
  }

  90% {
    clip-path: inset(45% 0 40% 0);
    transform: translate(-10px,10px);
  }

  100% {
    clip-path: inset(50% 50% 50% 50%);
    transform: translate(0);
  }
`;

export const Button = styled.button`
  padding: 10px 50px;
  font-size: 20px;
  border: none;
  border-radius: 5px;
  color: white;
  background-color: transparent;
  position: relative;
  cursor: pointer;
  z-index:10;

  &:after {
    clip-path: inset(50% 50% 50% 50%);
    content: 'Visit INE';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
  }

  &:hover::after {
    animation: ${glitchAnim} 1s;
    text-shadow: -3px -3px 0px #1df2f0, 3px 3px 0px #E94BE8;
    animation-timing-function: steps(2, end);
    background-color: transparent;
    border: 3px solid rgb(0, 255, 213);
  }

  &:hover {
    text-shadow: -1px -1px 0px #1df2f0, 1px 1px 0px #E94BE8;
    background-color: transparent;
    border: 1px solid rgb(0, 255, 213);
    box-shadow: 0px 10px 10px -10px rgb(0, 255, 213);
  }
`;

export const SVG = styled.svg`
    position: relative;
    top: 0;
    margin-left: 15px;
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke: #ffffff;
    stroke-width: 2;
    transform: translateX(-5px);
    transition: all 0.3s ease;
    &:hover:before {
        width: 100%;
        background: #b1dae7;
        }
        
        &:hover svg {
        transform: translateX(0);
        }
        
        &:active {
        transform: scale(0.95);
        }
`;
