import React, { useState, useEffect } from "react";
import "./resume.css";
import ReactImage from "../../images/imgs/react.png";
import PythonImage from "../../images/imgs/python.png";
import JavaImage from "../../images/imgs/java.png";
import css3Image from "../../images/imgs/css3.png";
import cImage from "../../images/imgs/c.png";
import NodejsImage from "../../images/imgs/nodejs.png";
import PHPImage from "../../images/imgs/php.png";
import MysqlImage from "../../images/imgs/mysql.png";
import JavascriptImage from "../../images/imgs/javascript.png";
import AndroidImage from "../../images/imgs/android-studio.png";
import HtmlImage from "../../images/imgs/html.png";
import BootstrapImage from "../../images/imgs/bootstrap.png";
import shortid from "shortid";

const Index = () => {
  // const [count, setCount] = useState(0);
  // useEffect(() => {
  //   console.log("count is ", count);
  //   if (count < 1) {
  //     console.log("fuck");
  //     //window.location.reload();
  //   }
  //   setCount(1);
  // }, [count]);
  // window.onload = function () {
  //   if (!window.location.hash) {
  //     window.location = window.location + "#loaded";
  //     window.location.reload();
  //   }
  // };

  return (
    <body class="u-body u-overlap u-overlap-transparent u-stick-footer">
      <section class="u-clearfix u-grey-15 u-section-1" id="carousel_9c55">
        <div class="u-clearfix u-expanded-width u-layout-wrap u-layout-wrap-1">
          <div class="u-layout">
            <div class="u-layout-row">
              <div
                class="
                u-container-style
                u-layout-cell
                u-size-30
                u-white
                u-layout-cell-1
              "
              >
                <div
                  class="
                  u-container-layout
                  u-valign-bottom-sm
                  u-valign-bottom-xs
                  u-container-layout-1
                "
                >
                  <div
                    class="
                    u-image u-image-circle u-preserve-proportions u-image-1
                  "
                    alt=""
                    data-image-width="500"
                    data-image-height="500"
                    data-animation-name="jackInTheBox"
                    data-animation-duration="1000"
                    data-animation-delay="0"
                    data-animation-direction=""
                  ></div>
                  <h2
                    class="
                    u-align-center
                    u-custom-font
                    u-font-montserrat
                    u-text
                    u-text-default
                    u-text-1
                  "
                    data-animation-name="bounceIn"
                    data-animation-duration="1000"
                    data-animation-delay="0"
                    data-animation-direction=""
                  >
                    I’m Sherin Stephen
                  </h2>
                  <p
                    class="u-align-center u-text u-text-2"
                    data-animation-name="bounceIn"
                    data-animation-duration="1000"
                    data-animation-delay="0"
                    data-animation-direction=""
                  >
                    Student &amp; Software Developer
                  </p>
                  <p
                    class="u-align-center u-text u-text-grey-30 u-text-3"
                    data-animation-name="bounceIn"
                    data-animation-duration="1000"
                    data-animation-delay="0"
                    data-animation-direction=""
                  >
                    Thiruvananthapuram ,Kerala ,India
                  </p>
                </div>
              </div>
              <div
                class="
                u-align-center
                u-container-style
                u-grey-90
                u-layout-cell
                u-size-30
                u-layout-cell-2
              "
              >
                <div class="u-container-layout u-container-layout-2">
                  <p
                    class="u-text u-text-4"
                    data-animation-name="bounceIn"
                    data-animation-duration="1000"
                    data-animation-delay="0"
                    data-animation-direction=""
                  >
                    <span style={{ fontWeight: 700 }}></span>
                    <span style={{ fontWeight: 700 }}>
                      Software Developer, photographer and travel enthusiast.
                    </span>
                  </p>
                  <p
                    class="u-small-text u-text u-text-variant u-text-5"
                    data-animation-name="bounceIn"
                    data-animation-duration="1000"
                    data-animation-delay="0"
                    data-animation-direction=""
                  >
                    <b>
                      I am a student and technophile who is interested in
                      creating new things. Currently pursuing B.Tech Computer
                      Science &amp; Engineering.My interest lies more in coding.
                      I love to create new things with code.My other hobbies are
                      watching movies, traveling, and listening to music.
                    </b>
                  </p>
                  <div
                    class="u-social-icons u-spacing-30 u-social-icons-1"
                    data-animation-name="bounceIn"
                    data-animation-duration="1000"
                    data-animation-delay="0"
                    data-animation-direction=""
                  >
                    <a class="u-social-url" target="_blank" href="">
                      <span
                        class="
                        u-icon
                        u-icon-circle
                        u-social-facebook
                        u-social-icon
                        u-icon-1
                      "
                      ></span>
                    </a>
                    <a class="u-social-url" target="_blank" href="">
                      <span
                        class="
                        u-icon
                        u-icon-circle
                        u-social-icon
                        u-social-twitter
                        u-icon-2
                      "
                      ></span>
                    </a>
                    <a class="u-social-url" target="_blank" href="">
                      <span
                        class="
                        u-icon
                        u-icon-circle
                        u-social-icon
                        u-social-instagram
                        u-icon-3
                      "
                      ></span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        class="
        u-align-center
        u-border-1
        u-border-grey-75
        u-clearfix
        u-grey-90
        u-section-2
      "
        id="carousel_e664"
      >
        <div class="u-clearfix u-sheet u-sheet-1">
          <h2
            class="u-text u-text-default u-text-1"
            data-animation-name="bounceIn"
            data-animation-duration="1000"
            data-animation-delay="0"
            data-animation-direction=""
          >
            What I do ?
          </h2>
          <div class="u-expanded-width-xs u-layout-grid u-list u-list-1">
            <div class="u-repeater u-repeater-1">
              <div
                class="
                u-align-center
                u-container-style
                u-grey-90
                u-list-item
                u-radius-20
                u-repeater-item
                u-shape-round
                u-list-item-1
              "
                data-animation-name="jackInTheBox"
                data-animation-duration="1000"
                data-animation-delay="0"
                data-animation-direction=""
              >
                <div
                  class="
                  u-container-layout
                  u-similar-container
                  u-valign-bottom-lg
                  u-valign-bottom-xl
                  u-container-layout-1
                "
                >
                  <img
                    class="
                    u-image u-image-default u-preserve-proportions u-image-1
                  "
                    src={ReactImage}
                    alt=""
                    data-image-width="512"
                    data-image-height="512"
                  />
                  <h6 class="u-align-center u-text u-text-default u-text-2">
                    React
                  </h6>
                </div>
              </div>
              <div
                class="
                u-container-style
                u-grey-90
                u-list-item
                u-radius-20
                u-repeater-item
                u-shape-round
                u-list-item-2
              "
                data-animation-name="jackInTheBox"
                data-animation-duration="1000"
                data-animation-delay="0"
                data-animation-direction=""
              >
                <div
                  class="
                  u-container-layout
                  u-similar-container
                  u-valign-bottom-lg
                  u-valign-bottom-xl
                  u-container-layout-2
                "
                >
                  <img
                    class="
                    u-image u-image-default u-preserve-proportions u-image-2
                  "
                    src={PythonImage}
                    alt=""
                    data-image-width="512"
                    data-image-height="512"
                  />
                  <h6 class="u-align-center u-text u-text-default u-text-3">
                    Python
                  </h6>
                </div>
              </div>
              <div
                class="
                u-align-center
                u-container-style
                u-grey-90
                u-list-item
                u-radius-20
                u-repeater-item
                u-shape-round
                u-list-item-3
              "
                data-animation-name="jackInTheBox"
                data-animation-duration="1000"
                data-animation-delay="0"
                data-animation-direction=""
              >
                <div
                  class="
                  u-container-layout
                  u-similar-container
                  u-valign-bottom-lg
                  u-valign-bottom-xl
                  u-container-layout-3
                "
                >
                  <img
                    class="
                    u-image u-image-default u-preserve-proportions u-image-3
                  "
                    src={NodejsImage}
                    alt=""
                    data-image-width="512"
                    data-image-height="512"
                  />
                  <h6 class="u-align-center u-text u-text-default u-text-4">
                    Node Js
                  </h6>
                </div>
              </div>
              <div
                class="
                u-align-center
                u-container-style
                u-grey-90
                u-list-item
                u-radius-20
                u-repeater-item
                u-shape-round
                u-list-item-4
              "
                data-animation-name="jackInTheBox"
                data-animation-duration="1000"
                data-animation-delay="0"
                data-animation-direction=""
              >
                <div
                  class="
                  u-container-layout
                  u-similar-container
                  u-valign-bottom-lg
                  u-valign-bottom-xl
                  u-container-layout-4
                "
                >
                  <img
                    class="
                    u-image u-image-default u-preserve-proportions u-image-4
                  "
                    src={PHPImage}
                    alt=""
                    data-image-width="512"
                    data-image-height="512"
                  />
                  <h6 class="u-align-center u-text u-text-default u-text-5">
                    PHP
                  </h6>
                </div>
              </div>
              <div
                class="
                u-align-center
                u-container-style
                u-grey-90
                u-list-item
                u-radius-20
                u-repeater-item
                u-shape-round
                u-list-item-5
              "
                data-animation-name="jackInTheBox"
                data-animation-duration="1000"
                data-animation-delay="0"
                data-animation-direction=""
              >
                <div
                  class="
                  u-container-layout
                  u-similar-container
                  u-valign-bottom-lg
                  u-valign-bottom-xl
                  u-container-layout-5
                "
                >
                  <img
                    class="
                    u-image u-image-default u-preserve-proportions u-image-5
                  "
                    src={JavaImage}
                    alt=""
                    data-image-width="512"
                    data-image-height="512"
                  />
                  <h6 class="u-align-center u-text u-text-default u-text-6">
                    JAva
                  </h6>
                </div>
              </div>
              <div
                class="
                u-align-center
                u-container-style
                u-grey-90
                u-list-item
                u-radius-20
                u-repeater-item
                u-shape-round
                u-list-item-6
              "
                data-animation-name="jackInTheBox"
                data-animation-duration="1000"
                data-animation-delay="0"
                data-animation-direction=""
              >
                <div
                  class="
                  u-container-layout
                  u-similar-container
                  u-valign-bottom-lg
                  u-valign-bottom-xl
                  u-container-layout-6
                "
                >
                  <img
                    class="
                    u-image u-image-default u-preserve-proportions u-image-6
                  "
                    src={JavascriptImage}
                    alt=""
                    data-image-width="512"
                    data-image-height="512"
                  />
                  <h6 class="u-align-center u-text u-text-default u-text-7">
                    Javascript
                  </h6>
                </div>
              </div>
              <div
                class="
                u-align-center
                u-container-style
                u-grey-90
                u-list-item
                u-radius-20
                u-repeater-item
                u-shape-round
                u-list-item-7
              "
                data-animation-name="jackInTheBox"
                data-animation-duration="1000"
                data-animation-delay="0"
                data-animation-direction=""
              >
                <div
                  class="
                  u-container-layout
                  u-similar-container
                  u-valign-bottom-lg
                  u-valign-bottom-xl
                  u-container-layout-7
                "
                >
                  <img
                    class="
                    u-image u-image-default u-preserve-proportions u-image-7
                  "
                    src={AndroidImage}
                    alt=""
                    data-image-width="512"
                    data-image-height="512"
                  />
                  <h6 class="u-align-center u-text u-text-default u-text-8">
                    Android Development
                  </h6>
                </div>
              </div>
              <div
                class="
                u-align-center
                u-container-style
                u-grey-90
                u-list-item
                u-radius-20
                u-repeater-item
                u-shape-round
                u-list-item-8
              "
                data-animation-name="jackInTheBox"
                data-animation-duration="1000"
                data-animation-delay="0"
                data-animation-direction=""
              >
                <div
                  class="
                  u-container-layout
                  u-similar-container
                  u-valign-bottom-lg
                  u-valign-bottom-xl
                  u-container-layout-8
                "
                >
                  <img
                    class="
                    u-image u-image-default u-preserve-proportions u-image-8
                  "
                    src={cImage}
                    alt=""
                    data-image-width="512"
                    data-image-height="512"
                  />
                  <h6 class="u-align-center u-text u-text-default u-text-9">
                    c++
                  </h6>
                </div>
              </div>
              <div
                class="
                u-align-center
                u-container-style
                u-grey-90
                u-list-item
                u-radius-20
                u-repeater-item
                u-shape-round
                u-list-item-9
              "
                data-animation-name="jackInTheBox"
                data-animation-duration="1000"
                data-animation-delay="0"
                data-animation-direction=""
              >
                <div
                  class="
                  u-container-layout
                  u-similar-container
                  u-valign-bottom-lg
                  u-valign-bottom-xl
                  u-container-layout-9
                "
                >
                  <img
                    class="
                    u-image u-image-default u-preserve-proportions u-image-9
                  "
                    src={HtmlImage}
                    alt=""
                    data-image-width="512"
                    data-image-height="512"
                  />
                  <h6 class="u-align-center u-text u-text-default u-text-10">
                    HTML5
                  </h6>
                </div>
              </div>
              <div
                class="
                u-align-center
                u-container-style
                u-grey-90
                u-list-item
                u-radius-20
                u-repeater-item
                u-shape-round
                u-list-item-10
              "
                data-animation-name="jackInTheBox"
                data-animation-duration="1000"
                data-animation-delay="0"
                data-animation-direction=""
              >
                <div
                  class="
                  u-container-layout
                  u-similar-container
                  u-valign-bottom-lg
                  u-valign-bottom-xl
                  u-container-layout-10
                "
                >
                  <img
                    class="
                    u-image u-image-default u-preserve-proportions u-image-10
                  "
                    src={css3Image}
                    alt=""
                    data-image-width="512"
                    data-image-height="512"
                  />
                  <h6 class="u-align-center u-text u-text-default u-text-11">
                    CSS3
                  </h6>
                </div>
              </div>
              <div
                class="
                u-align-center
                u-container-style
                u-grey-90
                u-list-item
                u-radius-20
                u-repeater-item
                u-shape-round
                u-list-item-11
              "
                data-animation-name="jackInTheBox"
                data-animation-duration="1000"
                data-animation-delay="0"
                data-animation-direction=""
              >
                <div
                  class="
                  u-container-layout
                  u-similar-container
                  u-valign-bottom-lg
                  u-valign-bottom-xl
                  u-container-layout-11
                "
                >
                  <img
                    class="
                    u-image u-image-default u-preserve-proportions u-image-11
                  "
                    src={BootstrapImage}
                    alt=""
                    data-image-width="512"
                    data-image-height="512"
                  />
                  <h6 class="u-align-center u-text u-text-default u-text-12">
                    BOOTSTRAP
                  </h6>
                </div>
              </div>
              <div
                class="
                u-align-center
                u-container-style
                u-grey-90
                u-list-item
                u-radius-20
                u-repeater-item
                u-shape-round
                u-list-item-12
              "
                data-animation-name="jackInTheBox"
                data-animation-duration="1000"
                data-animation-delay="0"
                data-animation-direction=""
              >
                <div
                  class="
                  u-container-layout
                  u-similar-container
                  u-valign-bottom-lg
                  u-valign-bottom-xl
                  u-container-layout-12
                "
                >
                  <img
                    class="
                    u-image u-image-default u-preserve-proportions u-image-12
                  "
                    src={MysqlImage}
                    alt=""
                    data-image-width="512"
                    data-image-height="512"
                  />
                  <h6 class="u-align-center u-text u-text-default u-text-13">
                    mysql
                  </h6>
                </div>
              </div>
            </div>
          </div>
          <p class="u-text u-text-default u-text-14">
            Adding More soon 😉&nbsp;
          </p>
        </div>
      </section>
      <section
        class="u-align-center u-clearfix u-grey-90 u-section-3"
        id="carousel_35af"
      >
        <div class="u-clearfix u-sheet u-sheet-1">
          <h2
            class="
            u-text
            u-text-default-lg
            u-text-default-md
            u-text-default-xl
            u-text-1
          "
            data-animation-name="bounceIn"
            data-animation-duration="1000"
            data-animation-delay="0"
            data-animation-direction=""
          >
            My Projects
          </h2>
          <p
            class="u-text u-text-2"
            data-animation-name="bounceIn"
            data-animation-duration="1000"
            data-animation-delay="0"
            data-animation-direction=""
          >
            My projects are always important to me whether it is big or small
            because I think projects are always necessary to highlight the
            skills acquired
          </p>
          <div class="u-expanded-width-xs u-list u-list-1">
            <div class="u-repeater u-repeater-1">
              <div
                class="
                u-align-center
                u-container-style
                u-grey-80
                u-list-item
                u-radius-15
                u-repeater-item
                u-shape-round
                u-list-item-1
              "
                data-animation-name="jackInTheBox"
                data-animation-duration="1000"
                data-animation-delay="0"
                data-animation-direction=""
              >
                <div
                  class="
                  u-container-layout u-similar-container u-container-layout-1
                "
                >
                  <span
                    class="
                    u-file-icon
                    u-grey-70
                    u-icon
                    u-icon-circle
                    u-spacing-27
                    u-icon-1
                  "
                  >
                    <img src={PHPImage} alt="" />
                  </span>
                  <h5
                    class="
                    u-custom-font u-font-raleway u-text u-text-default u-text-3
                  "
                  >
                    Comic Mailer
                  </h5>
                  <p class="u-text u-text-grey-60 u-text-4">
                    A simple App which is developed using core PHP that sends
                    users comic every 5 minutes with attachments
                  </p>
                </div>
              </div>
              <div
                class="
                u-align-center
                u-container-style
                u-grey-80
                u-list-item
                u-radius-15
                u-repeater-item
                u-shape-round
                u-list-item-2
              "
                data-animation-name="jackInTheBox"
                data-animation-duration="1000"
                data-animation-delay="0"
                data-animation-direction=""
              >
                <div
                  class="
                  u-container-layout u-similar-container u-container-layout-2
                "
                >
                  <span
                    class="
                    u-file-icon
                    u-grey-70
                    u-icon
                    u-icon-circle
                    u-spacing-27
                    u-icon-2
                  "
                  >
                    <img src={ReactImage} alt="" />
                  </span>
                  <h5
                    class="
                    u-custom-font u-font-raleway u-text u-text-default u-text-5
                  "
                  >
                    Contact Manager
                  </h5>
                  <p class="u-text u-text-grey-60 u-text-6">
                    A simple App made with Reactjs to understand the topics and
                    concept of the library which the contact with phone number
                    having curd operations on it
                  </p>
                </div>
              </div>
              <div
                class="
                u-align-center
                u-container-style
                u-grey-80
                u-list-item
                u-radius-15
                u-repeater-item
                u-shape-round
                u-list-item-3
              "
                data-animation-name="jackInTheBox"
                data-animation-duration="1000"
                data-animation-delay="0"
                data-animation-direction=""
              >
                <div
                  class="
                  u-container-layout u-similar-container u-container-layout-3
                "
                >
                  <span
                    class="
                    u-file-icon
                    u-grey-70
                    u-icon
                    u-icon-circle
                    u-spacing-27
                    u-icon-3
                  "
                  >
                    <img src={cImage} alt="" />
                  </span>
                  <h5
                    class="
                    u-custom-font u-font-raleway u-text u-text-default u-text-7
                  "
                  >
                    Water Quality Checker
                  </h5>
                  <p class="u-text u-text-grey-60 u-text-8">
                    project done as a semester project for evaluating the
                    quality of water with some data and predicts the approximate
                    quality of the water with that data
                  </p>
                </div>
              </div>
              <div
                class="
                u-align-center
                u-container-style
                u-grey-80
                u-list-item
                u-radius-15
                u-repeater-item
                u-shape-round
                u-list-item-4
              "
                data-animation-name="jackInTheBox"
                data-animation-duration="1000"
                data-animation-delay="0"
                data-animation-direction=""
              >
                <div
                  class="
                  u-container-layout u-similar-container u-container-layout-4
                "
                >
                  <span
                    class="
                    u-file-icon
                    u-grey-70
                    u-icon
                    u-icon-circle
                    u-spacing-27
                    u-icon-4
                  "
                  >
                    <img src={PythonImage} alt="" />
                  </span>
                  <h5
                    class="
                    u-custom-font u-font-raleway u-text u-text-default u-text-9
                  "
                  >
                    Fitness Calculator
                  </h5>
                  <p class="u-text u-text-grey-60 u-text-10">
                    Project was created as my semester Project for python
                    subject. This app is created with Tkinter GUI library. It
                    calculates our fitness according to the data given
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <footer
        class="u-align-center u-clearfix u-footer u-grey-80 u-footer"
        id="sec-4f96"
      >
        <div class="u-align-left u-clearfix u-sheet u-sheet-1"></div>
      </footer>
      <section class="u-backlink u-clearfix u-grey-80">
        <a class="u-link" href="https://sheri.in" target="_blank">
          <span> All Rights Reserved © 2021 </span>
        </a>
        <p class="u-text">
          <span> &nbsp;&nbsp; created By &nbsp;&nbsp;</span>
        </p>
        <a class="u-link" href="https://sheri.in" target="_blank">
          <span> Sherin Stephen</span>{" "}
        </a>
      </section>
    </body>
  );
};

export default Index;
