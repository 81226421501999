import styled from 'styled-components';

export const ProjectsContainer = styled.div`
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
background:#010606;
height:100%;
padding-bottom: 100px;

@media screen and (max-width: 768px){
    height:100%;
}

@media screen and (max-width: 480px){
    height:100%;
}

`
export const ProjectsWrapper = styled.div`
display:grid;
grid-template-columns:1fr 1fr 1fr;
align-items: center;
padding: 0 50px;

@media screen and (max-width: 1000px){
    grid-template-columns: 1fr 1fr;
}

@media screen and (max-width: 768px){
    grid-template-columns:1fr;
    padding: 0 20px;
}

`
export const ProjectsCard = styled.div`
background:#fff;
display:flex;
flex-direction: column;
justify-content:flex-start;
align-items: center;
border-radius: 10px;
max-height: 340px;
width: 300px;
padding: 30px;
margin: 10px;
box-shadow: 0 1px 3px rgba(0,0,0,0.2);
transition: all 0.2s ease-in-out;

&:hover{
    transform: scale(1.02);
    transition:all 0.2s ease-in-out;
    cursor: pointer;
}
`
export const ProjectsIcon = styled.img`
height: 160px;
width:160px;
margin-bottom:10px;
`
export const ProjectsH1 = styled.h1`
margin-top: 120px;
font-size: 2.5rem;
color: #fff;
margin-bottom: 64px;

@media screen and (max-width: 480px){
    font-size: 2rem;
}
`
export const ProjectsD = styled.p`
margin-top: 20px;
font-size: 1.5rem;
color: #f1f1f1;
margin-bottom: 64px;
text-align: center;
padding-left: 325px;
padding-right: 325px;

@media screen and (max-width: 480px){
    font-size: 18px;
    padding-left: 25px;
    padding-right: 25px;
}
`
export const ProjectsH2 = styled.h2`
font-size:1rem;
margin-bottom:10px;
`
export const ProjectsP = styled.p`
font-size:1rem;
text-align:center;
`