

import React, { useState } from "react";
import styled from "styled-components";
// import './style.css'
const KEY = "chukkudu@123";
// const KEY = "a";

function Chukks() {
  const [hasAccess, setHasAccess] = useState(false);
  const [keyInput, setKeyInput] = useState("");

  const handleKeyInput = (event) => {
    setKeyInput(event.target.value);
  };

  const handleAccess = () => {
    if (keyInput === KEY) {
      setHasAccess(true);
    } else {
      alert("Invalid key. Access denied.");
    }
  };

  return (
    <Container>
      {!hasAccess ? (
        // <div>
        //   <h2>Enter the key to access the page:</h2>
        //   <input type="password" value={keyInput} onChange={handleKeyInput} />
        //   <button onClick={handleAccess}>Access</button>
        // </div>
        <>
        <Card>
      <Input type="password" value={keyInput} onChange={handleKeyInput} placeholder="Enter your KEY!"/>
      <CuteButton onClick={handleAccess}>Enter</CuteButton>
      </Card>
      </>
      ) : (
        // <h1>You have access to the page!</h1>
        <Background>
          
      <Wrapper>
      <Image src="/images/dear.jpeg" alt="gift" />
        <Heading>Happy Birthday Vave !</Heading>
        <Subheading>To my amazing Chukkudu, (22 years old ayi)</Subheading>
        {/* <Message>Wishing you a day filled with joy and happiness, surrounded by all your loved ones.</Message> */}
        <Message1>

Thank you for being with me through thick and thin. You have been my support system, my confidant, and my partner in crime. You are an incredible person, and I feel so lucky to have you in my life. I hope this day brings you all the happiness, joy, and love you deserve.

I miss you more than words can express. But know that you are always in my thoughts and in my heart.

Lastly, I want to tell you that I love you to the moon and back. You are an amazing human being, and I am so grateful to have you as my chukkudu.

Cheers to another year of adventures, laughter, and love!</Message1>
        
      </Wrapper>
    </Background>
      )}
    </Container>
  );
}

export default Chukks;

const Container = styled.div`
display: flex;
    align-items: center;
    justify-content: center;
`
const Input = styled.input`
  border: 2px solid #e8e8e8;
  padding: 15px;
  border-radius: 10px;
  background-color: #212121;
  font-size: small;
  font-weight: bold;
  text-align: center;

  &:focus {
    outline-color: white;
    background-color: #212121;
    color: #e8e8e8;
    box-shadow: 5px 5px #888888;
  }
`;
const CuteButton = styled.button`
  padding: 10px 20px;
  background-color: #ff69b4;
  color: white;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  box-shadow: 2px 2px 5px #888888;
  margin-top: 10px;    
  &:hover {
    background-color: #ff97c3;
  }
`;

const Card = styled.div`
color: white;
display: grid;
gap: 5px;
overflow: visible;
padding: 90px;
`;

const Heading = styled.h1`
  font-size: 64px;
  font-weight: bold;
  color: #f7d6e0;
  text-shadow: 2px 2px #ff7f50;
  margin-bottom: 40px;
`;

const Subheading = styled.h2`
  font-size: 32px;
  font-weight: bold;
  color: #ff7f50;
  margin-bottom: 40px;
`;

const Message = styled.p`
  font-size: 24px;
  color: #f7d6e0;
`;

const Message1 = styled.p`
text-align: left;
margin-bottom: 20px;
padding: 31px;
`;

const Image = styled.img`
  max-width: 300px;
  margin-top: 50px;
`;
const Background = styled.div`
  background-color: #f7d6e0;
  background-image: url("https://cdn.pixabay.com/photo/2018/03/20/23/30/gift-3243734_960_720.png");
  background-position: center center;
  background-repeat: repeat;
  height: 100vh;
`;

const Wrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
  padding-top: 100px;
`;